import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from "@emotion/styled"

const Award = styled.li`
  div {
    display: inline;
    &:before {
      content: 'and ';
      padding-left: 5px;
    }
    &:first-of-type:before {
      content: '';
      padding-left: 0;
    }
  }
`

const Awardlist = () => {
    const data = useStaticQuery(graphql`
    {
        allContentfulAward(sort: {fields: year, order: DESC}) {
          edges {
            node {
              id
              title
              year
              project {
                projectTitle
                id
              }
            }
          }
        }
      }
    `)
    return (
        <ul>
        {data.allContentfulAward.edges.map(({node}) => (
            <Award key={node.id}>{node.title} {node.year}{node.project && <span> - {node.project.map(item => ( <div key={item.id}>{item.projectTitle}</div> ))}</span> }</Award>
        ))}
        </ul>
    )
}

export default Awardlist